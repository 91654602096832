<template>
    <div>
        <v-toolbar
                dense
                tabs
                color="primary darken-2"
                dark
        >
            <v-toolbar-title class="pl-2">Signup</v-toolbar-title>
        </v-toolbar>

        <v-container>
            <v-layout row>
                <v-flex xs12 md8 lg6 offset-md2 offset-lg3>
                    <v-slide-y-transition mode="out-in">
                        <div style="">
                            <h2 class="blue white--text font-weight-bold subheading pa-2 text-xs-left">Create Your Account</h2>
                            <v-divider></v-divider>                            
                            <v-card color="white pa-3 pt-1" style="background-color: rgb(255, 255, 255)!important" >
                                <v-layout row>
                                    <v-flex xs12 md10 offset-md1>            
                                        <v-layout row>
                                            <v-flex xs2 sm1>
                                                <i class="teal--text fas fa-user fa-2x mt-2 "> </i>                            
                                            </v-flex>
                                            <v-flex xs10 sm10>
                                                <v-text-field class="pa-0"
                                                    id="fullnames" v-model="fullnames" ref="fullnames"
                                                    :rules="[v => !!v || 'Your Full Names is required']"
                                                    label="Full Names"
                                                    single-line
                                                    color="teal"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs1 >
                                                <v-btn @click.stop="$store.commit('activateDialog', true); fullnames_help = true; getSignUpFieldHelp()" flat fab small outline color="black" class="my-3" style="height:20px; width: 20px" >
                                                    <i class="fas fa-question"> </i>                            
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs2 sm1>
                                                <i class="teal--text fas fa-user-circle fa-2x mt-2"> </i>                            
                                            </v-flex>
                                            <v-flex xs10 sm10>
                                                <v-text-field class="pa-0"
                                                    id="username" v-model="username" ref="username"
                                                    :rules="[v => !!v || 'Username is required']"
                                                    label="Username"
                                                    single-line
                                                    color="teal"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs1 >
                                                <v-btn @click.stop="$store.commit('activateDialog', true); username_help = true; getSignUpFieldHelp()" flat fab small outline color="black" class="my-3" style="height:20px; width: 20px" >
                                                    <i class="fas fa-question"> </i>                            
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs2 sm1>
                                                <i class="teal--text fas fa-lock fa-2x mt-2"> </i>                            
                                            </v-flex>
                                            <v-flex xs10 sm10>
                                                <v-text-field class="pa-0"
                                                    id="password" v-model="password" ref="password"
                                                    label="Enter Password"
                                                    :rules="[v => !!v || 'Your Password is required (at least 8 characters)']"
                                                    single-line
                                                    color="teal"
                                                    min="8"
                                                    :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                                    @click:append="() => (e1 = !e1)"
                                                    :type="e1 ? 'password' : 'text'"
                                                    counter
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs1 >
                                                <v-btn @click.stop="$store.commit('activateDialog', true); password_help = true; getSignUpFieldHelp()" flat fab small outline color="black" class="my-3" style="height:20px; width: 20px" >
                                                    <i class="fas fa-question"> </i>                            
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs2 sm1>
                                                <i class="teal--text fas fa-code fa-2x mt-2"> </i>                            
                                            </v-flex>
                                            <v-flex xs10 sm10>
                                                <v-text-field class="pa-0"
                                                    id="referal_code" v-model="referal_code" ref="referal_code"
                                                    label="Referal Code(Optional)"
                                                    single-line
                                                    color="teal"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs1 >
                                                <v-btn @click.stop="$store.commit('activateDialog', true); referal_code_help = true; getSignUpFieldHelp()" flat fab small outline color="black" class="my-3" style="height:20px; width: 20px" >
                                                    <i class="fas fa-question"> </i>                            
                                                </v-btn>
                                                
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs2 sm1>
                                                <i class="teal--text fas fa-phone fa-2x mt-2"> </i>                            
                                            </v-flex>
                                            <v-flex xs10 sm10>
                                                <input @click="clearPhoneField()" id="phone" required type="tel" value="Enter Phone Number" v-model="phone_number" ref="phone_number" name="phone" class="py-2 my-2" style="width: 100%!important; border-bottom: 1px grey solid" />
                                            </v-flex>
                                            <v-flex xs1 >
                                                <v-btn @click.stop="$store.commit('activateDialog', true); phone_number_help = true; getSignUpFieldHelp()" flat fab small outline color="black" class="my-3" style="height:20px; width: 20px" >
                                                    <i class="fas fa-question"> </i>                            
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <div class=" text-xs-right">
                                    <v-btn small class="white--text elevation-6" round color="teal darken-2" @click="createNewAccount()">
                                        <span> Next</span>
                                    </v-btn>
                                </div>
                            </v-card>
                        </div>
                    </v-slide-y-transition>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>


<script>
    import axios from 'axios'

    export default {

        data() {
            return {
                e1: true,
                e2: true,

                fullnames_help: false,
                referal_code_help: false,
                username_help: false,
                password_help: false,
                password_again_help: false,
                phone_number_help: false,

                username: "",
                referal_code: "",
                fullnames: "",
                password: "",
                password_again: "",
                phone_number: "Enter Phone Number",
                alert_message: "",
                phoneInput: [],
            }
        },
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | SignUp"
            if(this.$store.state.isAuthenticated){
                this.$store.commit('setSnackBarMessage', "You are currently signed into an account.")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)    
                var navigation = this.$router
                // const toPath = '/'
                console.log(this.prevRoute)
                navigation.push("/")
            }        
            this.initialiseIntel()
            this.$store.commit('setSaveItem', "")
        },

        methods:{
            initialiseIntel(){
                function getIp(callback) {
                    fetch('https://ipinfo.io/json?token=fa5e74d3e65812', { headers: { 'Accept': 'application/json' }})
                    .then((resp) => resp.json())
                    .catch(() => {
                        return {
                            country: 'cm',
                        };
                    })
                    .then((resp) => callback(resp.country));
                }

                const phoneInputField = document.querySelector("#phone");
                this.phoneInput = window.intlTelInput(phoneInputField, {
                    initialCountry: "cm",
                    geoIpLookup: getIp,
                    preferredCountries: ["cm", "us", "uk", "ci", "cg", "cf", "td", "ng", "gq", "ga"],
                    utilsScript:
                    "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
                });
            },
            clearPhoneField(){
                let phone_number = this.phoneInput.getNumber()
                console.log(phone_number)
                if(phone_number === "Enter Phone Number"){
                    this.phone_number = ""
                }

            },

            getSignUpFieldHelp(){
                if (this.fullnames_help){
                    this.fullnames_help = false
                    this.$store.commit('setDialogTitle', "Full Names")
                    this.$store.commit('setDialogMessage', "Here you are required to input all your names.")
                    this.$store.commit('activateDialog', true)
                }else if (this.username_help){
                    this.username_help = false
                    this.$store.commit('setDialogTitle', "Your User Name")
                    this.$store.commit('setDialogMessage', "Here you are required to input your User Name.")
                    this.$store.commit('activateDialog', true)
                }else if (this.password_help){
                    this.password_help = false
                    this.$store.commit('setDialogTitle', "Your Password")
                    this.$store.commit('setDialogMessage', "You are required to input your account password. This password needs to be at least 8 characters long. It is also advisable for you to write down this password so in case u forget, you can know where to get it.")
                    this.$store.commit('activateDialog', true)
                }else if (this.password_again_help){
                    this.password_again_help = false
                    this.$store.commit('setDialogTitle', "Re-enter Password")
                    this.$store.commit('setDialogMessage', "Here you are required to re-enter your password. This is to confirm that you didn't make any typing errors and know your password.")
                    this.$store.commit('activateDialog', true)
                }else if (this.phone_number_help){
                    this.phone_number_help = false
                    this.$store.commit('setDialogTitle', "Your Phone Number")
                    this.$store.commit('setDialogMessage', "Please input your phone number. This number will be used in sending of money and validating transactions. so make sure it is the right number.")
                    this.$store.commit('activateDialog', true)
                }else if (this.referal_code_help){
                    this.referal_code_help = false
                    this.$store.commit('setDialogTitle', "Who Refered You")
                    this.$store.commit('setDialogMessage', "Did you get a referal code to input as you create your account. Here's where to put it.")
                    this.$store.commit('activateDialog', true)
                }
            },

            async createNewAccount(){
                // console.log(this.phoneInput.isValidNumber(), this.phoneInput.getNumber())
                if(this.fullnames.length < 4){
                    this.$store.commit('setSnackBarMessage', "Your name are too short, please input atleast 2 of your names")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.fullnames.focus()  // this causes a an auto fucos to the element
                }
                else if(this.username.length < 4){
                    this.$store.commit('setSnackBarMessage', "User name is too short, user name needs to be at least 4 characters")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.username.focus()  // this causes a an auto fucos to the element
                }
                else if(this.password.length < 8){
                    this.$store.commit('setSnackBarMessage', "Password is too short, needs to be at least 8 characters")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.password.focus()  // this causes a an auto fucos to the element
                }
                else if(!this.phoneInput.isValidNumber()){
                    this.$store.commit('setSnackBarMessage', "Phone number is invalid. Please input a valid number")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.phone_number.focus()  // this causes a an auto fucos to the element
                }
                else {                    
                    this.$store.commit('setIsLoading', true)

                    var referal_flag = true
                    var contact_flag = false
                    if (this.referal_code.length > 0){
                        await axios
                            .get('/api/v1/savings/check/partner/referal_code/'+this.referal_code+'/')
                            .then(response => {
                                referal_flag = response.data  // get the data and fill into campaigns
                            })
                            .catch(error => {
                                if (error.response){
                                    this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                    this.$store.commit('setSnackBarColor', "red darken-4")
                                    this.$store.commit('activateSnackBar', true)
                                }else{
                                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                    this.$store.commit('setSnackBarColor', "red darken-4")
                                    this.$store.commit('activateSnackBar', true)
                                }
                            })
                    }
                    if (this.phoneInput.isValidNumber()){
                        await axios
                            .get('/api/v1/savings/check/client/contact/'+this.phoneInput.getNumber()+'/')
                            .then(response => {
                                contact_flag = response.data  // get the data and fill into campaigns
                            })
                            .catch(error => {
                                if (error.response){
                                    this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                    this.$store.commit('setSnackBarColor', "red darken-4")
                                    this.$store.commit('activateSnackBar', true)
                                }else{
                                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                    this.$store.commit('setSnackBarColor', "red darken-4")
                                    this.$store.commit('activateSnackBar', true)
                                }
                            })
                    }
                    
                    if(referal_flag && !contact_flag){
                        const formData = {
                            username: this.username,
                            password: this.password
                        }

                        await axios
                            .post('/api/v1/users/', formData)
                            .then(response => {
                                console.log(response.data)
                                axios
                                    .post('/api/v1/home/create/account/', {'username': this.username, 'password': this.password, 'phone_number': this.phoneInput.getNumber(), 'fullnames': this.fullnames, 'referal_code': this.referal_code})
                                    .then(response => {
                                        console.log(response.data[0])
                                        this.$store.commit('setSnackBarMessage', response.data[0].text)
                                        this.$store.commit('setSnackBarColor', response.data[0].color)
                                        this.$store.commit('activateSnackBar', true)

                                        var navigation = this.$router
                                        if (response.data[0].valid){
                                            // login user after creating account
                                            axios
                                                .post('/api/v1/token/login/', formData)
                                                .then(response => {
                                                    this.$store.state.username = this.username
                                                    const token = response.data.auth_token
                                                    this.$store.commit('setToken', token)
                                                    axios.defaults.headers.common['Authorization'] = "Token " + token
                                                    localStorage.setItem('token', token)
                                                })
                                                .catch(error => {
                                                    if (error.response){
                                                        for (const property in error.response.data){
                                                            this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                                                            this.$store.commit('setSnackBarColor', "red darken-4")
                                                            this.$store.commit('activateSnackBar', true)
                                                        }
                                                    }else{
                                                        this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                                                        this.$store.commit('setSnackBarColor', "red darken-4")
                                                        this.$store.commit('activateSnackBar', true)
                                                    }
                                                })

                                            setTimeout(function(){
                                                sessionStorage.setItem('url', window.location.href);
                                                navigation.push('/savings/campaigns/create/new_campaign')
                                                // navigation.push('/signup/otp/')
                                                // window.location.href = '/signup/two/'
                                            }, 4000)
                                        }
                                    })
                                    .catch(error => {
                                        if (error.response){
                                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                            this.$store.commit('setSnackBarColor', "red darken-4")
                                            this.$store.commit('activateSnackBar', true)
                                        }else{
                                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                            this.$store.commit('setSnackBarColor', "red darken-4")
                                            this.$store.commit('activateSnackBar', true)
                                        }
                                    })
                            })
                            .catch(error => {
                                if (error.response){
                                    for (const property in error.response.data){
                                        this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                                        this.$store.commit('setSnackBarColor', "red darken-4")
                                        this.$store.commit('activateSnackBar', true)
                                    }
                                }else{
                                    this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                                    this.$store.commit('setSnackBarColor', "red darken-4")
                                    this.$store.commit('activateSnackBar', true)
                                }
                            })
                    }
                    else{
                        if(!referal_flag){
                            this.$store.commit('setSnackBarMessage', "Referal code is invalid, please either you correct it or allow it blank")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                            this.$refs.referal_code.focus()  // this causes a an auto fucos to the element
                        }
                        else if(contact_flag){
                            this.$store.commit('setSnackBarMessage', "This phone number has already been used to create an account, please use another number")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                            this.$refs.phone_number.focus()  // this causes a an auto fucos to the element
                        }
                    }
                    this.$store.commit('setIsLoading', false)
                }

            },
        },
    }
</script>
